
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRaw,
  toRefs,
  unref,
} from "vue";
import { F2fAssignSpkInfoModel } from "@/api-client/modal";
import {
  EnumSpeakingType,
  F2FExaminerAssignCmd,
  F2FExaminerAssignExaminerDetail,
  F2FExaminerMatchRoomQuery,
  F2FExaminerQuery,
  F2FExaminerRep,
  F2FTestTimeDetail,
  F2FTestTimeRoomDetailQuery,
  F2FTestTimeRoomDetailRep,
  GetAllRegionRep,
  SpeakingSittingBlockEnum,
} from "@/api-client/client";
import { _Client } from "@/api-client";
import { Modal } from "ant-design-vue";
import { IsNullOrUndefined } from "@/utils/common";
import moment from "moment";

export default defineComponent({
  props: {
    spkInfo: { type: F2fAssignSpkInfoModel, required: true },
    testTime: null,
  },

  setup(props, context) {
    const timeMoment = moment;

    const tableLoading = ref<boolean>(false);
    const saveBtnLoading = ref<boolean>(false);
    const selectTop = ref<number | null>(null);

    const state = reactive<{
      regionList: GetAllRegionRep[];
      roomAndTestTime: F2FTestTimeRoomDetailRep;
      examinerList: F2FExaminerRep[];
      searchParames: F2FExaminerQuery;
      tableSelectedIndexList: string[];
    }>({
      regionList: new Array<GetAllRegionRep>(),
      roomAndTestTime: new F2FTestTimeRoomDetailRep(),
      examinerList: new Array<F2FExaminerRep>(),
      searchParames: {
        regionId: props.spkInfo.regionId,
        testTime: props.testTime!,
        resourceDeploymentId: props.spkInfo.resourceDeploymentId,
      } as F2FExaminerQuery,
      tableSelectedIndexList: new Array<string>(),
    });

    let tableSelectRowList = computed(() => {
      return state.examinerList.filter((examiner) => {
        return rowSelection.value.selectedRowKeys.includes(
          examiner.examinerNo!
        );
      });
    });
    const rowSelection = computed(() => {
      return {
        selectedRowKeys: state.tableSelectedIndexList,
        onChange: (selectedRowKeys: string[]) => {
          state.examinerList.map((examiner) => {
            if (
              selectedRowKeys.findIndex(
                (examinerNo) => examinerNo === examiner.examinerNo
              ) < 0
            ) {
              examiner.roomName = undefined;
              examiner.f2FTestTimeDetails = undefined;
            }
          });
          state.tableSelectedIndexList = selectedRowKeys;
        },
        getCheckboxProps: (record: F2FExaminerRep) => ({
          disabled: !record.examinerStatusAvailable,
        }),
      };
    });

    const tableActions = () => {
      async function getRoomAndTestTime() {
        state.roomAndTestTime = await _Client.spkDeploymentF2FClient.assignInitialization(
          new F2FTestTimeRoomDetailQuery({
            resourceDeploymentId: props.spkInfo.resourceDeploymentId!,
            testTime: props.testTime!,
          })
        );
      }
      function getTestTimeByRoom(examinerIndex: number) {
        let testTimeList: F2FTestTimeDetail[] | undefined;
        if (
          !state.examinerList[examinerIndex].roomName ||
          state.examinerList[examinerIndex].roomName === ""
        ) {
          return [];
        }
        if (
          state.roomAndTestTime.f2FRoomTestTimeList &&
          state.roomAndTestTime.f2FRoomTestTimeList.length > 0
        ) {
          let [
            roomTestTime,
          ] = state.roomAndTestTime.f2FRoomTestTimeList!.filter(
            (roomAndTestTime) =>
              roomAndTestTime.roomName ===
              state.examinerList[examinerIndex].roomName
          );
          testTimeList = roomTestTime.f2FRoomTestTimeList;
        }
        return testTimeList;
      }
      function handelRoomChange(examinerIndex: number) {
        state.examinerList[examinerIndex].f2FTestTimeDetails = undefined;
        getTestTimeByRoom(examinerIndex);
      }
      function roomAndTestTimeIsDisable(examinerIndex: number): boolean {
        let examiner = state.examinerList[examinerIndex];

        return (
          !examiner.examinerStatusAvailable ||
          unref(tableSelectRowList).findIndex(
            (selectedExaminer) =>
              selectedExaminer.examinerNo === examiner.examinerNo
          ) < 0
        );
      }
      async function handelMatchRoom() {
        let examinerNoList = unref(tableSelectRowList).map((selectRow) => {
          return selectRow.examinerNo!;
        });
        let examinerMatchRoomTestTimeList = await _Client.spkDeploymentF2FClient.match(
          new F2FExaminerMatchRoomQuery({
            resourceDeploymentId: props.spkInfo.resourceDeploymentId!,
            speakingSitting: props.testTime,
            examinerIdList: examinerNoList,
          })
        );
        examinerMatchRoomTestTimeList.forEach((examinerMatchRoomTestTime) => {
          let examinerIndex = state.examinerList.findIndex(
            (examiner) =>
              examiner.examinerNo === examinerMatchRoomTestTime.examinerNo
          );
          if (examinerIndex >= 0) {
            state.examinerList[examinerIndex].roomName =
              examinerMatchRoomTestTime.roomName;
            state.examinerList[examinerIndex].f2FTestTimeDetails =
              examinerMatchRoomTestTime.f2FTestTimeDetails;
          }
        });
      }

      return {
        getRoomAndTestTime,
        getTestTimeByRoom,
        handelRoomChange,
        roomAndTestTimeIsDisable,
        handelMatchRoom
      };
    };

    async function getRegionList() {
      state.regionList = await _Client.regionClient.getAll(false);
    }

    async function searchExaminerList() {
      try {
        tableLoading.value = true;
        state.examinerList = await _Client.spkDeploymentF2FClient.examinerQuery(
          state.searchParames
        );
        if (selectTop.value && selectTop.value > 0) {
          searchTopExaminerList();
        }
      } finally {
        tableLoading.value = false;
      }
    }
    function searchTopExaminerList() {
      try {
        tableLoading.value = true;
        state.tableSelectedIndexList = state.examinerList
          .filter((examiner) => {
            return examiner.examinerStatusAvailable;
          })
          .map((examiner) => {
            return examiner.examinerNo!;
          })
          .slice(0, selectTop.value || 0);
      } finally {
        tableLoading.value = false;
      }
    }
    function resetSearchParams() {
      state.searchParames = {
        borrowRegionUid: undefined,
        regionId: props.spkInfo.regionId,
        testTime: props.testTime!,
        resourceDeploymentId: props.spkInfo.resourceDeploymentId,
      } as F2FExaminerQuery;
      selectTop.value = null;
      state.examinerList = [];
    }
    function closeModal() {
      Modal.confirm({
        content:
          "Any data changed this time will not be saved. Are you sure you want to cancel without saving?",
        okText: "Yes",
        cancelText: "No",
        onOk: () => {
          context.emit("handleCloseAddExaminerModal");
        },
      });
    }
    async function handelAddExaminer() {
      try {
        saveBtnLoading.value = true;
        // 必须先填写room and testTime
        let notSelectRoomOrTestTime = unref(tableSelectRowList).some(
          (selectRow: F2FExaminerRep) => {
            return (
              IsNullOrUndefined(selectRow.roomName) ||
              IsNullOrUndefined(selectRow.f2FTestTimeDetails)
            );
          }
        );
        if (notSelectRoomOrTestTime) {
          Modal.warning({
            content: "Please select room/test time",
            okText: "Yes",
          });
          return;
        }

        let examinerDeploymentList: F2FExaminerAssignExaminerDetail[] = unref(
          tableSelectRowList
        ).map((selectRow) => {
          let assignExaminer: F2FExaminerAssignExaminerDetail = new F2FExaminerAssignExaminerDetail(
            {
              examinerNo: selectRow.examinerNo!,
              roomName: selectRow.roomName!,
              f2FTestTimeDetails: selectRow.f2FTestTimeDetails!,
            }
          );
          return assignExaminer;
        });
        await _Client.spkDeploymentF2FClient.assign(
          new F2FExaminerAssignCmd({
            resourceDeploymentId: props.spkInfo.resourceDeploymentId!,
            speakingSitting: props.testTime,
            detail: examinerDeploymentList,
          })
        );
        context.emit("handleCloseAddExaminerModal");
      } finally {
        saveBtnLoading.value = false;
      }
    }

    onMounted(async () => {
      tableActions().getRoomAndTestTime();
      getRegionList();
    });

    return {
      timeMoment,
      tableLoading,
      saveBtnLoading,
      selectTop,
      tableSelectRowList,
      ...toRefs(state),
      ...tableActions(),
      rowSelection,
      searchExaminerList,
      searchTopExaminerList,
      closeModal,
      handelAddExaminer,
      resetSearchParams,
    };
  },
});
