import moment from "moment-timezone";

// 强制转时区  零时区零点
export function addTimezoneByDbNameForce(time: Date | string, timezoneDatabaseName: string) {
  var currentTime = moment(time.toString()).format('YYYY-MM-DD');
  var value = moment.tz(currentTime, 'YYYY-MM-DD', timezoneDatabaseName).toDate()
  return value;
}

// 转至特定时区的日期
export function ConvertTimeZoneDate(time: string, timeZone: string) {
  return moment.tz(time, 'YYYY-MM-DD', timeZone);
}


// day: '2020-10-09'
export function IsInSomeDay(day: string, time: Date | string) {
  return moment(day).diff(moment(time), 'day') === 0
}

// 限制选择天数（rangeNum为天数）
export function DatePickerRange(rangeNum: number) {
  let minTime: any = null
  let maxTime: any = null
  let pickerOptions = {
    onPick: (time: any) => {
      // 只选择了开始时间
      if (!time.maxDate) {
        let timeRange = rangeNum * 24 * 60 * 60 * 1000
        minTime = time.minDate.getTime()
        maxTime = time.minDate.getTime() + timeRange
      } else {
        minTime = maxTime = null
      }
    },
    disabledDate(time: any) {
      if (minTime && maxTime) {
        return time.getTime() < minTime || time.getTime() > maxTime
      }
    }
  }
  return pickerOptions
}
