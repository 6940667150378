import { defineComponent, onMounted, reactive, ref, toRefs, unref } from "vue";
import FooterBar from "@/components/FooterBar/index.vue";
import { EnumSpeakingType, F2FExaminerArrangementQuery, F2FExaminerArrangementRep } from "@/api-client/client";
import { _Client } from "@/api-client";
import { routeLocationKey, useRouter } from "vue-router";
import { F2fAssignSpkInfoModel } from "@/api-client/modal";
import moment from "moment";
import AssignTable from "@/views/examiner/deployment/assign/f2f-assign/components/assignTable.vue";
import {addTimezoneByDbNameForce} from "@/utils/DateExtensions";

export default defineComponent({
    components: { AssignTable, FooterBar },
    setup(){
        const timeMoment = moment
        const {currentRoute, back} = useRouter();
        const route = unref(currentRoute);
        const searchExaminerArrangementParams: F2FExaminerArrangementQuery = new F2FExaminerArrangementQuery({
            resourceDeploymentId: route.params.resourceDeploymentId.toString()
        })

        const pageLoading = ref<boolean>(false)

        const state = reactive<{arrangementRep: F2FExaminerArrangementRep, spkInfo: F2fAssignSpkInfoModel }>({
            arrangementRep: new F2FExaminerArrangementRep(),
            spkInfo: new F2fAssignSpkInfoModel()
        })

        async function getArrangement() {
            try{
                pageLoading.value = true
                state.arrangementRep = await _Client.spkDeploymentF2FClient.arrangementQuery(searchExaminerArrangementParams)
                state.spkInfo = new F2fAssignSpkInfoModel(<F2fAssignSpkInfoModel>{
                    resourceDeploymentId: state.arrangementRep.resourceDeploymentId,
                    speakingDay: moment(addTimezoneByDbNameForce(state.arrangementRep.speakingDay!, 'Etc/GMT-0')).format('DD/MM/YYYY'),
                    speakingType: state.arrangementRep.speakingType,
                    regionId: state.arrangementRep.regionId,
                    centerAbbreviation: state.arrangementRep.centerAbbreviation,
                    centerCity: state.arrangementRep.centerCity,
                    centerName: state.arrangementRep.centerName,
                    isBackDeploymentPage: state.arrangementRep.isBackDeploymentPage
                })
            }finally{
                pageLoading.value = false
            }
        }

        onMounted(async () =>{
            await getArrangement();
        })   

        return { timeMoment, pageLoading, ...toRefs(state), getArrangement, back};
    }
})